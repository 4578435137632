<template>
  <span :class="`status-${type}`">
    {{ statusText }}
  </span>
</template>

<script>
import VueTypes from 'vue-types';
import localize from '../../filters/localize.filter';

export default {
  name: 'StatusCard',
  props: { type: VueTypes.oneOf([0, 1, 2, 3, 4, 9, 5]).def(0) },
  computed: {
    statusText() {
      const object = {};

      return (object[this.type] = localize('statuses')[this.type]);
    },
  },
};
</script>

<style lang="scss" scoped>
  $contractStatuses: 0 'not-active',
  1 'active',
  2 'moderation',
  3 'expired',
  4 'expired',
  5 'cancel',
  9 'completed';

  //active
  $activeBG: rgba(255, 164, 29, 0.15);
  $activeColor: #ffa41d;
  //completed
  $completedBG: #dff4e8;
  //cancel && not-active
  $cancelBG: rgba(248, 67, 67, 0.15);
  //expired
  $expiredBG: rgba(112, 112, 112, 0.15);
  $expiredColor: #707070;

  @each $statusCode, $status in $contractStatuses {
    .status-#{$statusCode} {
      padding: 8px 12px;

      font-weight: normal;
      font-size: 14px;
      line-height: 16px;

      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;

      @if $status == 'active' {
        background-color: $activeBG;
        color: $activeColor;
      } @else if $status == 'moderation' {
        background-color: $expiredBG;
        color: $expiredColor;
      } @else if $status == 'completed' {
        background-color: $completedBG;
        color: $success;
      } @else if $status == 'expired' {
        background-color: $cancelBG;
        color: $fail;
      } @else {
        background-color: $expiredBG;
        color: $expiredColor;
      }
    }
  }
</style>
