<template>
  <div class="contract">
    <div class="container fluid">
      <p class="contracts-page-title">
        {{ $localize('profile')['contract']['contracts'] }}
      </p>

      <div class="row">
        <div class="col-xl-7 col-ml-7">
          <Search
            @search-handler="filter"
            :placeholder="$localize('profile')['contract']['searchField']"
            bg="#fff"
          />
        </div>
      </div>

      <div class="row" v-if="contracts.length > 0">
        <div
          class="col-xxl-3 col-xl-4 col-ml-4 col-lg-4 col-md-6"
          :class="{ 'mt-32': isLoading }"
          v-for="(contract, i) in contracts"
          :key="i"
        >
          <pu-skeleton v-if="isLoading" height="146px"></pu-skeleton>
          <contract-card v-else v-bind="contract" />
        </div>
      </div>

      <p class="no-contract" v-else>
        {{ $localize('profile')['contract']['empty'] }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import loadingMixin from '@/mixins/loading.mixin';

import Search from '@/components/shop_section/searchCategory.vue';
import ContractCard from '@/components/profile/ContractCard.vue';

export default {
  name: 'ContractsPage',
  mixins: [loadingMixin],
  data: () => ({
    contractsData: [],
    searchResult: [],
  }),
  computed: { ...mapGetters({ contracts: 'filter/result' }) },
  components: {
    Search,
    ContractCard,
  },
  methods: {
    filter(contract_id) {
      const result = this.contractsData.filter(
        (contract) => contract.contract_id == contract_id,
      );

      if (result.length > 0) {
        this.$store.dispatch('filter/set', result);
      } else {
        this.$store.dispatch('filter/cancel');
      }

      if (!contract_id) {
        this.$store.dispatch('filter/set', this.contractsData);
      }
    },
  },
  async created() {
    const { data: response } = await this.$axios.get('buyer/contracts');

    if (response.status === 'success') {
      this.contractsData = response.contracts
      await this.$store.dispatch('filter/set', response.contracts);
    }
  },
};
</script>

<style lang="scss" scoped>
  .w-100 {
    width: 100%;
  }

  .contract {
    padding: 48px 44px;
    min-height: calc(100vh - 96px);
    background: $grey;
    width: auto;
    margin-right: -15px;
    margin-left: -15px;

    .contracts-page-title {
      font-weight: 900;
      font-size: 32px;
      line-height: 40px;
      color: $black;
      margin-bottom: 24px;

      @media (max-width: $mobile) {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 16px;
      }
    }
  }

  .no-contract {
    margin-top: 24px;
    font-size: 28px;
  }

  @media (max-width: 1199px) {
    .no-contract {
      text-align: center;
      font-size: 18px;
    }
  }

  @media (max-width: 575px) {
    .contract {
      padding: 27px 0px;
      min-height: calc(100vh - 48px);
    }
  }

  @media (min-width: 992px) {
    .col-lg-4 {
      flex: 0 0 auto;
      width: 33.3333333333% !important;
    }
  }

  /*@media (min-width: 1200px) {*/
  /*  .col-ml-4 {*/
  /*    flex: 0 0 auto;*/
  /*    width: 25% !important;*/
  /*  }*/
  /*}*/

  @media (min-width: 1400px) {
    .col-xl-4 {
      width: calc(100% / 12 * 4) !important;
    }
  }

  @media (min-width: 1600px) {
    .col-xxl-3 {
      width: calc(100% / 12 * 3) !important;
    }
  }
</style>
