<template>
  <router-link
    tag="div"
    :to="{ name: 'contract', params: { id: contract_id } }"
    class="profile-progress_card"
  >
    <div class="top">
      <div class="title">
        <h4>
          {{ $localize('profile')['contract']['number'] }} № {{ contract_id }}
        </h4>
        <p>
          {{ $localize('profile')['contract']['remaining'] }}
          {{ this.$numberFormat(remainder) }}
          {{ $localize('profile')['currency'] }}
        </p>
      </div>

      <div class="right">
        <status-card :type="status"></status-card>
      </div>
    </div>

    <div class="bottom">
      <div class="text">
        <h3>
          {{ $localize('profile')['contract']['nextPay'] }}
          {{ next_pay }}
        </h3>

        <p>
          {{ this.$numberFormat(current_pay) }}
          <span>{{ $localize('profile')['currency'] }}</span>
        </p>
      </div>

<!--            <a-->
<!--              :href="`http://cabinet.paymart.uz/storage/contract/${contract_id}/buyer_account_${contract_id}.pdf`"-->
<!--              target="_blank"-->
<!--              class="download"-->
<!--            >-->
<!--              <img src="../../assets/icons/download.svg" alt="">-->
<!--            </a>-->
    </div>
    <div class="progress">
      <div class="progress-inner" :style="{ width: status === 9 ? '100%' : '0%' }"></div>
    </div>
  </router-link>
</template>

<script>
import VueTypes from 'vue-types';
import StatusCard from '../cards/StatusCard';

export default {
  props: {
    contract_id: VueTypes.number.isRequired,
    period: VueTypes.number.isRequired,
    status: VueTypes.number.isRequired,
    remainder: VueTypes.oneOfType([Number, String]),
    current_pay: VueTypes.oneOfType([Number, String]),
    next_pay: VueTypes.string.isRequired,
    monthly_payment: VueTypes.oneOfType([Number, String]),
  },
  components: { StatusCard },
  methods: {
    pushRoute(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
  .profile {
    &-progress_card {
      position: relative;
      background: #ffffff;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;

      margin-top: 32px;
      padding: 16px;

      transition: box-shadow .2s linear;

      &:hover {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04)
      }

      .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;

        h4 {
          font-weight: bold;
          font-size: 16px;
          line-height: 28px;
          color: $black;
          cursor: pointer;
        }

        p {
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: $black;
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          font-size: 14px;
          line-height: 16px;
          color: $black;
          font-weight: 400;
          margin-bottom: 4px;
        }

        p {
          font-weight: 900;
          font-size: 20px;
          line-height: 25px;
          color: $main;
        }

        .download {
          cursor: pointer;
        }
      }

      .right {
        a {
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          letter-spacing: 0.02em;
          color: $main;
        }
      }

      .progress {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 4px;
        background: #eaeaea;
        overflow: hidden;

        &-inner {
          height: 100%;
          background: $main;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .profile-progress_card {
      padding: 12px;
      margin-top: 16px;
    }
  }
</style>
